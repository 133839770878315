<template>
  <section class="section">
    <div class="container">
      <div class="columns is-centered has-text-centered hit-cta">
        <div class="column">
          <h2 class="title title-font is-spaced" v-html="$t('airdrop_how_h2')" />
          <br>
          <br>
        </div>
      </div>
      <div class="columns is-centered has-text-centered">
        <!-- Enter Your Email -->
        <div class="column is-3">
          <div class="hit-icon">
            <img src="@/assets/img/airdrop/icon_join.svg" :alt="$t('airdrop_how1_h3') + ' icon'">
          </div>
          <h3 class="title is-4" v-html="$t('airdrop_how1_h3')" />
          <p class="subtitle subtitle-spaced" v-html="$t('airdrop_how1_p')" />
        </div>
        <div class="column is-1" />
        <!-- Prove You're Human -->
        <div class="column is-3">
          <div class="hit-icon">
            <img src="@/assets/img/airdrop/icon_nobot.svg" :alt="$t('airdrop_how2_h3') + ' icon'">
          </div>
          <h3 class="title is-4" v-html="$t('airdrop_how2_h3')" />
          <p
            class="subtitle subtitle-spaced"
          >{{ (customInvite === 'fractal') ? $t('airdrop_how2_p_fractal') : $t('airdrop_how2_p') }}</p>
        </div>
        <div class="column is-1" />
        <!-- Get Tokens at Launch -->
        <div class="column is-3">
          <div class="hit-icon">
            <img src="@/assets/img/airdrop/icon_tokens.svg" :alt="$t('airdrop_how3_h3') + ' icon'">
          </div>
          <h3 class="title is-4" v-html="$t('airdrop_how3_h3')" />
          <p class="subtitle subtitle-spaced" v-html="$t('airdrop_how3_p')" />
        </div>
      </div>

      <div class="columns faq">
        <div class="column has-text-centered">
          <p class="is-size-5">
            <span v-html="$t('airdrop_how_faq1')" />
            <router-link to="/airdrop/faq" v-html="$t('airdrop_how_faq2')" />
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
	name: "HowItWorks",
	props: {
		customInvite: {
			type: String,
			required: false,
			default: "",
		},
	},
};
</script>

<style scoped>
/* .section {
    padding-top: 80px;
    padding-bottom: 80px;
} */
.hit-cta .title {
	margin-bottom: 0;
}
.hit-icon {
	margin-bottom: 10px;
}
.faq {
	margin-top: 2rem;
}
</style>