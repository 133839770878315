<template>
  <section
    class="hero"
    :class="{
      'hero-fractal': customInvite === 'fractal',
      'hero-qic': customInvite === 'quorainnercircle',
      'hero-tokenomi': customInvite === 'tokenomi',
      'hero-bt': customInvite === 'bitcointalk' || customInvite === 'ghayas',
      'hero-picpukk': customInvite === 'picpukk',
      'hero-tokenomi': customInvite === 'altcoinpost',
    }"
  >
    <Navbrand v-if="referredBy !== '' || customInvite !== ''" :customBranding="customInvite" />
    <Navbar v-else />

    <div class="hero-body" :class="{ 'referral-spacing': referredBy }">
      <div class="container">
        <div class="columns">
          <div class="column is-7-tablet is-6-desktop is-5-widescreen">
            <h1
              class="title is-size-1 title-font"
              :class="{ 'has-text-white': customInvite === 'quorainnercircle' }"
              v-text="chooseTitle()"
            />
            <div class="column is-paddingless">
              <p
                class="subtitle is-size-4"
                :class="{
                  'has-text-white': customInvite === 'quorainnercircle',
                }"
                v-text="chooseSubtitle()"
              />
            </div>
            <div
              class="column pl-0 pt-20"
              :class="{ 'has-text-white': customInvite === 'quorainnercircle' }"
            >
              <GetStartedButton airdropPage :customInvite="customInvite" :referredBy="referredBy" :userAlreadyLoggedIn="isUserLoggedInTradingApp()" />
            </div>
          </div>
          <div class="column">
            <figure class="airdrop-image">
              <!-- Fractal Airdrop Image -->
              <picture v-if="customInvite === 'fractal'">
                <source type="image/webp" srcset="@/assets/img/airdrop/airdrop_gift_fractal.webp">
                <source type="image/png" srcset="@/assets/img/airdrop/airdrop_gift_fractal.png">
                <img src="@/assets/img/airdrop/airdrop_gift_fractal.png" :alt="$t('airdrop_img')">
              </picture>
              <!-- Quora Airdrop Image -->
              <picture v-else-if="customInvite === 'quorainnercircle'">
                <source type="image/webp" srcset="@/assets/img/airdrop/qic_small.webp">
                <source type="image/png" srcset="@/assets/img/airdrop/qic_small.png">
                <img src="@/assets/img/airdrop/qic_small.png" :alt="$t('airdrop_img')">
              </picture>
              <!-- CryptoExplorer Gift Image -->
              <picture v-else-if="customInvite === 'cryptoexplorer'">
                <source type="image/webp" srcset="@/assets/img/airdrop/airdrop_gift_ce.webp">
                <source type="image/png" srcset="@/assets/img/airdrop/airdrop_gift_ce.png">
                <img src="@/assets/img/airdrop/airdrop_gift_ce.png" :alt="$t('airdrop_img')">
              </picture>
              <!-- Picpukk Profile Image -->
              <picture v-else-if="customInvite === 'picpukk'">
                <source type="image/webp" srcset="@/assets/img/airdrop/picpukk.webp">
                <source type="image/png" srcset="@/assets/img/airdrop/picpukk.png">
                <img src="@/assets/img/airdrop/picpukk.png" :alt="$t('airdrop_img')">
              </picture>
              <!-- Tokenomi Gift Image -->
              <picture v-else-if="customInvite === 'tokenomi'">
                <source type="image/webp" srcset="@/assets/img/airdrop/airdrop_gift_tokenomi.webp">
                <source type="image/png" srcset="@/assets/img/airdrop/airdrop_gift_tokenomi.png">
                <img src="@/assets/img/airdrop/airdrop_gift_tokenomi.png" :alt="$t('airdrop_img')">
              </picture>
              <!-- Alex Crypto Gift Image -->
              <picture v-else-if="customInvite === 'alexcrypto'">
                <source type="image/webp" srcset="@/assets/img/airdrop/airdrop_gift_ac.webp">
                <source type="image/png" srcset="@/assets/img/airdrop/airdrop_gift_ac.png">
                <img src="@/assets/img/airdrop/airdrop_gift_ac.png" :alt="$t('airdrop_img')">
              </picture>
              <!-- Crypto Analyzer Gift Image -->
              <picture v-else-if="customInvite === 'cryptoanalyzer'">
                <source type="image/webp" srcset="@/assets/img/airdrop/airdrop_gift_ca.webp">
                <source type="image/png" srcset="@/assets/img/airdrop/airdrop_gift_ca.png">
                <img src="@/assets/img/airdrop/airdrop_gift_ca.png" :alt="$t('airdrop_img')">
              </picture>
              <!-- BitcoinTalk Gift Image -->
              <picture v-else-if="customInvite === 'bitcointalk'">
                <source type="image/webp" srcset="@/assets/img/airdrop/airdrop_gift_bitcointalk.webp">
                <source type="image/png" srcset="@/assets/img/airdrop/airdrop_gift_bitcointalk.png">
                <img src="@/assets/img/airdrop/airdrop_gift_bitcointalk.png" :alt="$t('airdrop_img')">
              </picture>
              <!-- ghayas Gift Image -->
              <picture v-else-if="customInvite === 'ghayas'">
                <source type="image/webp" srcset="@/assets/img/airdrop/airdrop_gift_tcg.webp">
                <source type="image/png" srcset="@/assets/img/airdrop/airdrop_gift_tcg.png">
                <img src="@/assets/img/airdrop/airdrop_gift_tcg.png" :alt="$t('airdrop_img')">
              </picture>
              <!-- Altcoinpost Gift Image -->
              <picture v-else-if="customInvite === 'altcoinpost'">
                <source type="image/webp" srcset="@/assets/img/airdrop/airdrop_gift_altcoin.webp">
                <source type="image/png" srcset="@/assets/img/airdrop/airdrop_gift_altcoin.png">
                <img src="@/assets/img/airdrop/airdrop_gift_altcoin.png" :alt="$t('airdrop_img')">
              </picture>
              <!-- Keybase Gift Image -->
              <picture v-else-if="customInvite === 'keybase'">
                <source type="image/webp" srcset="@/assets/img/airdrop/airdrop_gift_keybase.webp">
                <source type="image/png" srcset="@/assets/img/airdrop/airdrop_gift_keybase.png">
                <img src="@/assets/img/airdrop/airdrop_gift_keybase.png" :alt="$t('airdrop_img')">
              </picture>
              <!-- FedericoSellitti Gift Image -->
              <picture v-else-if="customInvite === 'federicosellitti'">
                <source type="image/webp" srcset="@/assets/img/airdrop/airdrop_gift_fs.webp">
                <source type="image/png" srcset="@/assets/img/airdrop/airdrop_gift_fs.png">
                <img src="@/assets/img/airdrop/airdrop_gift_fs.png" :alt="$t('airdrop_img')">
              </picture>
              <!-- CryptoGrowth Gift Image -->
              <picture v-else-if="customInvite === 'cryptogrowth'">
                <source type="image/webp" srcset="@/assets/img/airdrop/airdrop_gift_cryptogrowth.webp">
                <source type="image/png" srcset="@/assets/img/airdrop/airdrop_gift_cryptogrowth.png">
                <img src="@/assets/img/airdrop/airdrop_gift_cryptogrowth.png" :alt="$t('airdrop_img')">
              </picture>
              <!-- CoinFunda Gift Image -->
              <picture v-else-if="customInvite === 'coinfunda'">
                <source type="image/webp" srcset="@/assets/img/airdrop/airdrop_gift_coinfunda.webp">
                <source type="image/png" srcset="@/assets/img/airdrop/airdrop_gift_coinfunda.png">
                <img src="@/assets/img/airdrop/airdrop_gift_coinfunda.png" :alt="$t('airdrop_img')">
              </picture>
              <!-- Redeeem Gift Image -->
              <picture v-else-if="customInvite === 'redeeem'">
                <source type="image/webp" srcset="@/assets/img/airdrop/airdrop_gift_redeeem.webp">
                <source type="image/png" srcset="@/assets/img/airdrop/airdrop_gift_redeeem.png">
                <img src="@/assets/img/airdrop/airdrop_gift_redeeem.png" :alt="$t('airdrop_img')">
              </picture>
              <!-- NEConnected Gift Image -->
              <picture v-else-if="customInvite === 'neconnected'">
                <source type="image/webp" srcset="@/assets/img/airdrop/airdrop_gift_nec.webp">
                <source type="image/png" srcset="@/assets/img/airdrop/airdrop_gift_nec.png">
                <img src="@/assets/img/airdrop/airdrop_gift_nec.png" :alt="$t('airdrop_img')">
              </picture>
              <!-- Tetris Gift Image -->
              <picture v-else-if="customInvite === 'tetrisotoko'">
                <source type="image/webp" srcset="@/assets/img/airdrop/airdrop_gift_tetris.webp">
                <source type="image/png" srcset="@/assets/img/airdrop/airdrop_gift_tetris.png">
                <img src="@/assets/img/airdrop/airdrop_gift_tetris.png" :alt="$t('airdrop_img')">
              </picture>
              <!-- Draper Gift Image -->
              <picture v-else-if="customInvite === 'draper'">
                <source type="image/webp" srcset="@/assets/img/airdrop/airdrop_gift_draper.webp">
                <source type="image/png" srcset="@/assets/img/airdrop/airdrop_gift_draper.png">
                <img src="@/assets/img/airdrop/airdrop_gift_draper.png" :alt="$t('airdrop_img')">
              </picture>
              <!-- Regular Gift Image -->
              <picture v-else>
                <source type="image/webp" srcset="@/assets/img/airdrop/airdrop_gift.webp">
                <source type="image/png" srcset="@/assets/img/airdrop/airdrop_gift.png">
                <img src="@/assets/img/airdrop/airdrop_gift.png" :alt="$t('airdrop_img')">
              </picture>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Navbrand from "@/components/partials/Navbrand";
import Navbar from "@/components/partials/Navbar";
import GetStartedButton from "@/components/partials/GetStartedButton";
import { isUserLoggedInTradingApp } from '@/utils/helpers';

import { i18n } from "@/plugins/i18n";

export default {
	name: "Hero",
	components: {
		Navbrand,
		Navbar,
		GetStartedButton,
	},
	props: {
		referredByName: {
			type: String,
			required: false,
			default: "",
		},
		referredBy: {
			type: String,
			required: false,
			default: "",
		},
		customInvite: {
			type: String,
			required: false,
			default: "",
		},
		inviteMessage: {
			type: String,
			required: false,
			default: "",
		},
		airdropAmount: {
			type: Number,
			required: true,
			default: 0,
		},
		referralBonus: {
			type: Number,
			required: false,
			default: 0,
		},
		registerURL: {
			type: String,
			required: false,
			default: "",
		},
	},
	methods: {
		chooseTitle() {
			const amount =
				this.airdropAmount > 0 ? Math.floor(this.airdropAmount) : 100;
			return (
				i18n.t("airdrop_h1_1") +
				amount.toLocaleString() +
				i18n.t("airdrop_h1_2")
			);
		},
		chooseSubtitle() {
			let subtitle = "";
			if (this.referredBy || this.customInvite) {
				if (this.referredBy) {
					// Regular Referral Link
					if (this.referredByName) {
						let firstName = this.referredByName
							.replace(/ .*/, "")
							.toLowerCase();
						firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
						subtitle = firstName + i18n.t("airdrop_sub_ref");
					} else {
						subtitle = i18n.t("airdrop_sub_ref3");
					}
				} else if (this.inviteMessage === "CUSTOM") {
					// Extra custom invite, more page changes
					// Customize Subtitle
					switch (this.customInvite) {
						case "cryptouniverse":
							subtitle =
								"Добро Пожаловать! Вас пригласила группа Crypto Universe на участие в Aирдропе. Получите крипто за подписку.";
							return subtitle;
						case "quorainnercircle":
							subtitle =
								"Welcome Quora Crypto Community! Get 100 MPH to invest in your favorite market like Amazon, Bitcoin, or Gold.¹";
							subtitle = subtitle.replace(
								"100",
								Math.floor(this.airdropAmount).toLocaleString()
							);
							return subtitle;
						case "alexcrypto":
							subtitle =
								"Друзья, присоединяйтесь к торговой платформе, на которой вы можете торговать 24/7 акциями, форекс и криптовалютой.";
							return subtitle;
						case "keybase":
							subtitle =
								"Welcome Keybase fans! Our Airdrop gives you 100 MPH to invest in your favorite market like Amazon, Bitcoin, or Gold.¹";
							subtitle = subtitle.replace(
								"100",
								Math.floor(this.airdropAmount).toLocaleString()
							);
							return subtitle;
						default:
							break;
					}
				} else {
					// Average Custom Invite
					subtitle = this.inviteMessage + i18n.t("airdrop_sub_ref2");
				}
			} else {
				// No referral link, regular subtitle
				subtitle = i18n.t("airdrop_sub");
			}

			// Replace reward amount if known
			if (this.airdropAmount > 0) {
				const reward = Math.floor(this.airdropAmount).toLocaleString();
				subtitle = subtitle.replace("100", reward);
			}
			return subtitle;
		},
		register() {
			window.location.href = this.registerURL;
		},
		isUserLoggedInTradingApp
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hero {
	/* background-color: #f7fafc; */
	background-color: #eff3ff;
}
/* Custom Hero Backgrounds */
.hero-fractal {
	background-color: #f6f7f8;
}
.hero-qic {
	background-color: #b92b27;
}
.hero-tokenomi {
	background-color: #fbf5ff;
}
.hero-bt {
	background-color: #fff5ef;
}
.hero-picpukk {
	background-color: #fff5ef;
}
/* **************************** */
.hero .subtitle {
	line-height: 1.5;
}
.pt-40 {
	padding-top: 40px;
}
.pt-5 {
	padding-top: 5px;
}
.mt-20-tablet {
	margin-top: 20px;
}

.mt-55-tablet {
	margin-top: 30px;
}

.centered {
	display: flex;
	justify-content: center;
	align-items: center;
}

.spacing-featured {
	margin-top: 40px;
}

.airdrop-image {
	margin: 0 auto;
	max-height: 440px;
	max-width: 600px;
	margin-top: -170px;
}
.hero-qic .airdrop-image {
	max-width: 500px;
}

@media only screen and (min-width: 1408px) {
	.referral-spacing {
		margin-bottom: 36px;
	}
}

@media only screen and (min-width: 1216px) {
	.hero-qic .airdrop-image {
		margin-top: -100px;
	}
}

@media only screen and (max-width: 1215px) {
	.airdrop-image {
		margin-top: -80px;
	}
	.hero-qic .airdrop-image {
		max-width: 400px;
		margin-top: -40px;
	}
}
@media only screen and (max-width: 1023px) {
	.is-center-touch {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.airdrop-image {
		margin-top: -60px;
	}
}
@media only screen and (max-width: 768px) {
	.is-center-mobile {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.hero.hero.is-fullheight {
		min-height: initial;
	}
	.airdrop-image {
		margin-top: -100px;
		max-height: 590px;
	}
	.hero-qic .airdrop-image {
		margin-top: -30px;
	}
}
@media only screen and (max-width: 500px) {
	.hero .title {
		margin-top: -20px;
	}
	.market-mobile-hide {
		display: none;
	}
	.text-left-mobile {
		text-align: left !important;
	}
	.mt-55-tablet {
		margin-top: inherit;
	}
	.mt-20-tablet {
		margin-top: inherit;
	}
	.spacing-featured {
		margin-top: -40px;
	}
	.airdrop-image {
		margin-top: -40px;
		max-height: 400px;
	}
	.hero-qic .airdrop-image {
		max-width: 80%;
		margin-top: -20px;
	}
}
@media only screen and (min-height: 1200px) {
	.hero.is-fullheight {
		min-height: 1200px;
	}
}

.pt-0 {
	padding-top: 0px;
	margin-top: -100px;
}
.pl-0 {
	padding-left: 0px;
}
.pt-35 {
	padding-top: 35px;
}
.mt-10 {
	margin-top: 10px;
}
.is-voffset {
	margin-bottom: -180px;
}
.is-26 {
	max-height: 26px;
}
.input-button {
	border-radius: 5px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
	border: none;
	background: #00c386;
	font-family: "Roboto", sans-serif;
	font-weight: 600 !important;
	color: #fff;
	transition-delay: 0s;
	transition-duration: 0.5s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
	outline: none;
	width: 100%;
}

.input-button:hover {
	cursor: pointer;
	outline: none;
	background: #00d492;
	color: #fff;
	box-shadow: 0px 1px 2px rgba(0, 161, 111, 0.2),
		0px 6px 12px rgba(0, 161, 111, 0.2);
	transform: translateY(0);
	transition-delay: 0s;
	transition-duration: 0.5s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
</style>
